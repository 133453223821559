// ** MUI Components
import { styled } from '@mui/material/styles'

interface FooterIllustrationsProp {
    image?: string
}

// Styled Components
const MaskImg = styled('img')(() => ({
    bottom: 0,
    zIndex: -1,
    width: '100%',
    position: 'absolute'
}))

const ShapeImg = styled('img')(({ theme }) => ({
    left: '15%',
    bottom: '12%',
    position: 'absolute',

    [theme.breakpoints.down('lg')]: {
        bottom: '7%'
    }
}))

const FooterIllustrations = (props: FooterIllustrationsProp) => {
    // ** Props
    const { image } = props

    const src = image || '/misc-coming-soon-object.png'
        return (
            <>
                <ShapeImg alt='shape' src={src} />
                <MaskImg alt='mask' src={`/misc-mask-light.png`} />
            </>
        )
}

export default FooterIllustrations
