import React from 'react';
import UnderMaintenance from "./UnderMaintenance";

function App() {
  return (
        <UnderMaintenance/>
  );
}

export default App;
